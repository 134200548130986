function loadSliders() {
	$('.clients__inner').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 5,
		prevArrow: "<button class='clients__button clients__button--prev' aria-label='Previous' type='button'><img src='https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1723556391/Sites/full-of-bounce/arrow.png' alt=''/></button>",
		nextArrow: "<button class='clients__button clients__button--next' aria-label='Next' type='button'><img src='https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1723556391/Sites/full-of-bounce/arrow.png' alt=''/></button>",
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
	$('.testimonials .carousel-inner').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 3,
		prevArrow: "<button class='testimonials__button testimonials__button--prev' aria-label='Previous' type='button'><img src='/theme/arrow.png' alt=''/></button>",
		nextArrow: "<button class='testimonials__button testimonials__button--next' aria-label='Next' type='button'><img src='/theme/arrow.png' alt=''/></button>",
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
}



function moreCategories() {
	var moreCategories = document.querySelector(".featured-categories__more");
	var btnText = document.getElementById("MoreCategories");

	if (moreCategories.style.display === "none" || moreCategories.style.display === "") {

		btnText.innerHTML = "Load Less";
		moreCategories.style.display = "flex";
	} else {
		moreCategories.style.display = "none";
		btnText.innerHTML = "Load More";
	}
}

var sliderScript = document.getElementById("deferredSlickScript");
sliderScript.addEventListener('load', function () {
	loadSliders();
});
$(document).ready(function () {
	$('.textContent a').addClass("has-tooltip");
	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});
	$("a.has-tooltip").tooltip({
		placement: "auto"
	});
	// this removes the '/' between testimonial author & date if there are any testimonials
	// its difficult to style otherwise & is rarely wanted.
	var testimonialAuthors = $(".RotatorTestimonialContent a[itemprop='author']");
	if (testimonialAuthors.length > 0) {
		$(".RotatorTestimonialContent a[itemprop='author']").each(function () {
			$(this)[0].nextSibling.nodeValue = '';
		});
	}
	if ($('#HomepageMarker').length > 0) {
		$('.header__logo').addClass("fadeInUp2");
	} else {
		$('.header__logo').css('visibility', 'visible');
	}
	function fixHeights() {
		$('.RotatorTestimonial').sameHeight();
		$('.featured-categories__title').sameHeight();
		if (window.matchMedia('(min-width:768px)').matches) {
		}
	}
	setTimeout(fixHeights, 300);
	$(window).on('resize',
		function () {
			setTimeout(fixHeights, 300);
		});
});
